
Pace.on('done', function () {
	var root = document.getElementsByTagName('app-root');
	var loading = document.getElementById('loading');
	if (root && root.length > 0) {
		setTimeout(function () {
			loading.style.display = "none";
			root[0].style.display = "block";
		}, 500);
	}
});
